// https://edge.uniconsent.com/json/
// 根据这个连接获取到的country_code与region_code
;(function waitGEO() {
    var readyGEO
    if (window['UnicI'] && window['UnicI'].geo && window['UnicI'].geo !== '-') {
      readyGEO = true
      // country_code EU
      if (window['UnicI'].geo === 'EU') {
        $('#unic-gdpr').show()
      }
      // region_code CA
      if (window['UnicI'].geo === 'CA') {
        $('#unic-ccpa').show()
      }
    }
    if (!readyGEO) {
      setTimeout(waitGEO, 200)
    }
  })()