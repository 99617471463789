import './GEO'

window.site = {
  share: {
    weibo: {
      relateUid: 6348562215,
      appkey: 1265420217
    },
    facebookId: 2604994089524272
  },
  schemePrefix: 'dealmoonde://',   // scheme前缀
  adjustDeepLinkDomain: 'l2l5.adj.st' // adjust deep link域名，App中有配置
}
